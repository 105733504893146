import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionIntro,
  SectionClientFeatures,
  SectionProfessionalFeatures,
  SectionReviews,
  SectionProfessionals,
  SectionProjects,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import classNames from 'classnames';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    listings,
    queryListingsInProgress,
    queryListingsError,
    projects,
    queryProjectsInProgress,
    queryProjectsError,
    currentUser,
    isCurrentUserProfessional,
    isCurrentUserClient,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const showListingsSection = listings?.length > 0;
  const showProjectsSection = projects?.length > 0;
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              currentUser={currentUser}
              isCurrentUserProfessional={isCurrentUserProfessional}
              isCurrentUserClient={isCurrentUserClient}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={classNames(css.sectionContentFirstChild, css.sectionIntro)}>
                <SectionIntro intl={intl} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionClientFeatures />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionReviews)}>
                <SectionReviews />
              </div>
            </li>
            <li className={css.sectionContent}>
              <div className={classNames(css.sectionContent, css.sectionProfessionalFeatures)}>
                <SectionProfessionalFeatures />
              </div>
            </li>
            {showListingsSection ? (
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionProfessionals
                    listings={listings}
                    queryListingsInProgress={queryListingsInProgress}
                    queryListingsError={queryListingsError}
                  />
                </div>
              </li>
            ) : null}
            {showProjectsSection ? (
              <li className={css.section}>
                <div className={classNames(css.sectionContent, css.sectionProjects)}>
                  <SectionProjects
                    listings={projects}
                    queryListingsInProgress={queryProjectsInProgress}
                    queryListingsError={queryProjectsError}
                  />
                </div>
              </li>
            ) : null}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    listingRefs,
    queryListingsInProgress,
    queryListingsError,
    projectRefs,
    queryProjectsInProgress,
    queryProjectsError,
  } = state.LandingPage;
  const { currentUser, isCurrentUserProfessional, isCurrentUserClient } = state.user;

  const listings = getListingsById(state, listingRefs);
  const projects = getListingsById(state, projectRefs);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    listings,
    queryListingsInProgress,
    queryListingsError,
    projects,
    queryProjectsInProgress,
    queryProjectsError,
    currentUser,
    isCurrentUserProfessional,
    isCurrentUserClient,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
